import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { FaInstagram, FaFacebook, FaEnvelope } from 'react-icons/fa';
import './contactus.scss';

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_xp7pcjg', 'template_kmurjfp', form.current, 'JzVv-_97JcUFfPPNk')
      .then(
        () => {
          alert('Your message has been sent successfully!');
          form.current.reset(); // Reset the form fields
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('Failed to send your message, please try again later.');
        },
      );
  };

  return (
    <section className='contactUs container section' id="contactus">
      <div className='secContainer'>
        <div className='secIntro'>
          <h1 className='secTitle'>Contact Us</h1>
          <p>We are here to answer any questions you may have about our services.</p>

          <form ref={form} onSubmit={sendEmail}>
            <div className='formGroup'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='user_name' placeholder='Your name' required />
            </div>

            <div className='formGroup'>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' name='user_email' placeholder='Your email' required />
            </div>

            <div className='formGroup'>
              <label htmlFor='message'>Message</label>
              <textarea id='message' name='message' rows='5' placeholder='Your message' required></textarea>
            </div>

            <button type='submit'>Send Message</button>
          </form>
        </div>

        <div className='contactInfo'>
          <div className='contactHeader'>Contact Information</div>
          <div className='socialIcons'>
            <a href="https://www.instagram.com/resinglesnaps/" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram className='icon1' /></a>
            <a href="https://www.facebook.com/profile.php?id=100090017743899" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebook className='icon2' /></a>
            <a href="mailto:singlesnaps.com@gmail.com?subject=Inquiry&body=Hello, I would like to ask about..." aria-label="Email"><FaEnvelope className='icon3' /></a>
          </div>
          <p>
            <b>You can reach us at:</b><br />
            DoorNO : 1-55  kovil street,<br />
            Dharmapuri City, TamilNadu State,<br/> 
            India Country <br />
            <b>Email</b>: singlesnaps.com@gmail.com<br />
            <b>Phone</b>: +91 92064 41478
          </p>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
