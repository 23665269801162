  import React, {useState} from 'react'
import './navbar.scss'
import { IoCameraOutline } from "react-icons/io5";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";


const Navbar=() =>{

  const [active, setActive] = useState('navBar')

  const showNav =()=>{
    setActive('navBar activeNavbar')
  }

  const removeNav =()=>{
    setActive('navBar')
  }

  const [transparent, setTransparent] = useState('header')

  const addBg =()=>{

    if(window.scrollY >= 10){
      setTransparent('header activeHeader')
    }else{
      setTransparent('header')
    }
  }
  window.addEventListener('scroll',addBg)

  
  return (
    <section className ='navBarSection'>
      <div className={transparent}>

        <div className="logoDiv">
          <a href="/" className="logo" >
            <h1 className='flex'> <IoCameraOutline className='icon' />SingleSnaps </h1>
          </a>
        </div>

        <div className={active}>
          <ul className="navLists flex">

            <li className="navItem">
            <button className='btn loginBtn'>
              <a href="#contactus" className="navLink">Contact</a>
            </button>
            </li>

            <li className="navItem">
            <button className='btn loginBtn'>
              <a href="#aboutus" className="navLink">AboutUs</a>
            </button>
            </li>

            <li className="navItem">
            <button className='btn loginBtn'>
              <a href="#services" className="navLink">Service</a>
              </button>
            </li>

            {/* <div className="headerBtns flex">
              <button className='btn loginBtn'>
                <a href="/">Login</a>
              </button>
              <button className='btn signBtn'>
                <a href="/">Sign Up</a>
              </button>
            </div> */}

          </ul>

          <div onClick={removeNav} className="closeNavBar">
          <AiFillCloseCircle className ='icon'/>
          </div>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className='icon'/>
        </div>

      </div>
    </section>
  )
}

export default Navbar