import React from 'react';
import SwiperCore, { Pagination, EffectCoverflow, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './service.scss';
import gallary from '../ServicePages/Hdr'

// Importing Images
import img2 from '../../Assets/singleImage.jpg';
import img3 from '../../Assets/staging.jpg';
import img4 from '../../Assets/dayToDusk.jpg';
import img5 from '../../Assets/twlight.jpeg';
import img6 from '../../Assets/flashAmbient.jpg';
import img8 from '../../Assets/itemRemoval.jpeg';

SwiperCore.use([Pagination, EffectCoverflow, Autoplay]);

const Posts = [

  {
    id: 2,
    postImage: img2,
    title: "Single Image Editing",
    desc: 'Basic photo editing services for single images.'
  },
  {
    id: 3,
    postImage: img3,
    title: "Virtual Staging",
    desc: 'Add virtual furniture and decorations to empty spaces.'
  },
  {
    id: 4,
    postImage: img4,
    title: "Day to Dusk Conversion",
    desc: 'Transform daytime photos into stunning dusk views.'
  },
  {
    id: 5,
    postImage: img5,
    title: "Twilight Photography",
    desc: 'Capture properties in the beautiful twilight hour.'
  },
  {
    id: 6,
    postImage: img6,
    title: "Flash Ambient Blending",
    desc: 'Combine flash and ambient shots for perfect lighting.',
    href: "#Hdr" 
  },
  {
    id: 8,
    postImage: img8,
    title: "Item Removal",
    desc: 'Remove unwanted items or objects from images.'
  },
];

const Service = () => {
  return (
    <section className='service-section container section' id="services">
      <div className='service-bg' style={{ backgroundImage: `url(https://c1.wallpaperflare.com/preview/725/423/706/sky-blue-blue-sky-background-thumbnail.jpg)` }}>
        <div className='service-sec-container'>
          <h1 className='service-sec-title'>Services We Offer</h1>
          <p>Explore the range of services we provide to enhance your images.</p>
          <div className="service-main-container">
            <Swiper 
              className='service-swiper'
              modules={[Pagination, EffectCoverflow, Autoplay]}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 3,
                  slideShadows: true
              }}
              loop={true}
              pagination={{clickable: true}}
              autoplay={{
                  delay: 5000,
                  disableOnInteraction: false
              }}
              breakpoints={{
                  640: {
                      slidesPerView: 2
                  },
                  768: {
                      slidesPerView: 1
                  },
                  1024: {
                      slidesPerView: 2
                  },
                  1560: {
                      slidesPerView: 3
                  },
              }}
            >
              {Posts.map((data) => (
                <SwiperSlide 
                  key={data.id} 
                  style={{ backgroundImage: `url(${data.postImage})` }} 
                  className="service-swiper-slide"
                >
                  <div className="service-slide-content">
                    <h2>{data.title}</h2>
                    <p>{data.desc}</p>
                    <a 
                      href={data.href || `${data.postImage}`} 
                      target={data.href ? "_self" : "_blank"} 
                      rel="noopener noreferrer" 
                      className='service-slider-btn'
                    >
                      Explore
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
