import React from 'react'
import './app.css'
import Navbar from './Components/Navbar/Navbar'
import Home from './Components/Home/Home'
import About from './Components/About/About'
import Service from './Components/Services/service'
import ContactUs from './Components/Contactus/ContactUs'
import Hdr from './Components/ServicePages/Hdr'


function App() {
  return (
    <>
      <Navbar />
      <Home/>     
      <Service />
      <About />
      <ContactUs/>
      <Hdr/>
    </>
  )
}

export default App