import React, { useState } from 'react';
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import './hdr.scss';

import { daytoduskImages } from '../../Assets/singlesnap/daytoduskImages';
import { flashImages } from '../../Assets/singlesnap/flashImages';
import { outdoorImages } from '../../Assets/singlesnap/outdoorImages';
import { removalImages } from '../../Assets/singlesnap/removalImages';
import { singleimageImages } from '../../Assets/singlesnap/singleimageImages';
import { twilightImages } from '../../Assets/singlesnap/twilightImages';
import { virtualStagingImages } from '../../Assets/singlesnap/virtualStagingImages';

const folders = [
  { name: 'Day to Dusk', images: daytoduskImages },
  { name: 'Flash Ambient', images: flashImages },
  { name: 'Outdoor', images: outdoorImages },
  { name: 'Item Removal', images: removalImages },
  { name: 'Single Image', images: singleimageImages },
  { name: 'Twilight', images: twilightImages },
  { name: 'Virtual Staging', images: virtualStagingImages }
];

const Hdr = () => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleImageClick = (index) => {
        setCurrentIndex(index);
        setIsOpen(true);
    };

    return (
        <div className="hdr-container" id="Hdr">
            <h1>Image Galleries</h1>
            <div className="folder-headings">
                {folders.map((folder, index) => (
                    <h2 
                        key={index} 
                        className={`folder-heading ${selectedImages === folder.images ? 'active' : ''}`}
                        onClick={() => setSelectedImages(folder.images)}
                    >
                        {folder.name}
                    </h2>
                ))}
            </div>
            {selectedImages.length > 0 && (
                <div className="hdr-grid">
                    {selectedImages.map((image, index) => (
                        <div 
                            className="hdr-grid-item" 
                            key={index} 
                            onClick={() => handleImageClick(index)}
                        >
                            <img
                                src={image}
                                alt={`Image ${index + 1}`}
                                className="hdr-image"
                            />
                            <div className="hdr-overlay">
                                <div className="hdr-overlay-text">Image {index + 1}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {isOpen && (
                <Lightbox
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    slides={selectedImages.map(image => ({ src: image }))}
                    index={currentIndex}
                />
            )}
        </div>
    );
};

export default Hdr;
