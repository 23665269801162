import React from 'react';
import './home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import ContactUs from '../Contactus/ContactUs';

const Home = () => {
  return (
    <section className='home'>
      <div className="secContainer container">

        <div className="homeText">

          <h1 className='title'>
            Elevate Your Property Listings with Expert Real Estate Photo Editing
          </h1>

          <p className="subTitle">
            Transform Your Listings and Increase Sales with Professional Image Enhancement
          </p>

          <div className='icon'>
            <a href='<ContactUs/>'>
              <FontAwesomeIcon icon={faHome} size="3x" />
            </a>
          </div>
          
        </div>

        {/* <div className="homeCard grid">

          <div className="nameDiv">
            <label htmlFor="Name">Name</label>
            <input type="text" placeholder='your name' />
          </div>

          <div className="numberDiv">
            <label htmlFor="number">Number</label>
            <input type="numeric" placeholder='number' />
          </div>

          <div className="emailDiv">
            <label htmlFor="Email">Email</label>
            <input type="email" placeholder='email' />
          </div>

          <button className="btn"> 
          <a href='#'> submit </a>
          </button>

        </div> */}

      </div>
    </section>
  );
}

export default Home;
