import React from 'react';
import './about.scss';
import ceoImage from '../../Assets/ceo1.jpeg';

const AboutUs = () => {
  return (
    <section className='aboutUs container section' id="aboutus">
      <div className='intro'>
        <h1 className='title'>About Us</h1>
        <p>We are a company dedicated to providing top-notch real estate photo editing services to our clients.</p>
      </div>

      <div className='team'>
        <div className='teamContainer'>
          <div className='memberImage'>
            <img src={ceoImage} alt="Silambarasan" className='ceoImage' />
            <div className='memberName'>
              <h3>Mr. Silambarasan</h3>
            </div>
          </div>
          <div className='memberDescription'>
            <h2 className='teamTitle'>Meet Our CEO</h2>
            <p className='description'>
              I have 9 years of experience in the real estate photo editing service, starting my journey in 2015. I have extensive experience handling software like Adobe Photoshop and Lightroom with licensed versions. I have worked with various clients from the US, UK, Australia, and New Zealand. According to the needs of the client, I provide photo editing services with consistency, time efficiency, and delivery according to commitments. Now, I have started a new company with trained editors, and we work as a team to start our journey. Our company is located in Tamil Nadu, India.
            </p>
          </div>
        </div>
      </div>

      <div className='mission'>
        <h2 className='missionTitle'>Our Mission</h2>
        <p>To deliver high-quality products and services that bring value to our customers.</p>
      </div>
    </section>
  );
}

export default AboutUs;
